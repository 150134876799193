// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import 'yekan';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';

.rtl {
	direction: rtl;	
}

.thumbnail {
	padding: 0.25rem;
	background-color: #f8fafc;
	border: 1px solid #dee2e6;
	border-radius: 0.25rem;
	max-width: 100%;
}

.qualified {
	background: #eff6fd;
}

.accepted {
	background: #f1fdef;
}

.rejected {
	background: #fdefef;
}
